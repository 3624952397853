export default {
  type: 'form',
  url: '/config/farmBuyNotice/getContent',
  edit: '/config/farmBuyNotice/update',
  data: [
    {
      type: 'rich',
      wrapperCol: 24,
      key: 'content'
    }
  ]
}
